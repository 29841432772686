import router from '@/router'
const system = {
  state: {
    systemInfo: {}, //  系统信息
    maskLoading: false, // 全局loading
    loadingText: '', // 全局loading文字
    showWallet: false, // 显示连接钱包
    todoUrl: '',
    noticeType: 0, // 全局notice弹窗类型1项目介绍2用户协议3计算公式4如何加速5质押规则6推荐规则7数值说明
    resetPage: false, // 刷新当前页面
    trxNotice: false, // 是否trx钱包登录
    isAnnounce: false, // 首页通知
    announceText: {},
  },
  mutations: {
    SET_SYSTEMINFO: (state, systemInfo) => {
      state.systemInfo = systemInfo
    },
    SET_MASKLOADING: (state, maskLoading) => {
      state.maskLoading = maskLoading
    },
    SET_LOADINGTEXT: (state, loadingText) => {
      state.loadingText = loadingText
    },
    SET_SHOWWALLET: (state, showWallet) => {
      state.showWallet = showWallet
    },
    SET_TODOURL: (state, todoUrl) => {
      state.todoUrl = todoUrl
    },
    SET_NOTICETYPE: (state, noticeType) => {
      state.noticeType = noticeType
    },
    SET_RESETPAGE: (state, resetPage) => {
      state.resetPage = resetPage
    },
    SET_TRXNOTICE: (state, trxNotice) => {
      state.trxNotice = trxNotice
    },
    SET_ISANNOUNCE: (state, isAnnounce) => {
      state.isAnnounce = isAnnounce
    },
    SET_ANNOUNCETEXT: (state, announceText) => {
      state.announceText = announceText
    }
  },
  actions: {
    SetSystemInfo({commit}, systemInfo) {
      commit('SET_SYSTEMINFO', systemInfo)
    },
    SetMaskLoading({commit}, maskLoading) {
      commit('SET_MASKLOADING', maskLoading)
    },
    SetLoadingText({commit}, loadingText) {
      commit('SET_LOADINGTEXT', loadingText)
    },
    SetShowWallet({commit}, showWallet) {
      commit('SET_SHOWWALLET', showWallet)
    },
    checkLink({rootState, dispatch}) {
      return new Promise((resolve, reject) => {
        if (rootState.wallet.account) {
          dispatch('SetShowWallet', false)
          resolve()
        } else {
          dispatch('SetShowWallet', true)
          reject()
        }
      })
    },
    setTodoUrl({commit}, todoUrl) {
      commit('SET_TODOURL', todoUrl)
    },
    actionTodo({state, dispatch}) {
      let url = state.todoUrl
      if (url) {
        router.push(url)
        dispatch('setTodoUrl', '')
      }
    },
    setNoticeType({commit}, noticeType) {
      commit('SET_NOTICETYPE', noticeType)
    },
    setResetPage({commit}, resetPage) {
      commit('SET_RESETPAGE', resetPage)
    },
    SetTrxNotice({commit}, trxNotice) {
      commit('SET_TRXNOTICE', trxNotice)
    },
    SetIsAnnounce({commit}, isAnnounce) {
      commit('SET_ISANNOUNCE', isAnnounce)
    },
    SetAnnounceText({commit}, announceText) {
      commit('SET_ANNOUNCETEXT', announceText)
    },
  }
}

export default system
