import { createRouter, createWebHashHistory } from 'vue-router'

function  importFile(path) {
  // return resolve => require(['@/views/' + path], resolve)
  return () => require.ensure([], (require) => require(`@/views/${path}`))
}

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: importFile('Home'),
  },
  {
    path: '/exchange',
    name: 'exchange',
    meta: {
      title: '单币质押'
    },
    component: importFile('exchange/Exchange'),
  },
  {
    path: '/liquidity',
    name: 'liquidity',
    meta: {
      title: '流动性质押'
    },
    component: importFile('exchange/Liquidity'),
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      title: '团队奖金提现'
    },
    component: importFile('exchange/Withdraw'),
  },
  {
    path: '/admit',
    name: 'admit',
    meta: {
      title: '团队奖金提现'
    },
    component: importFile('exchange/Admit'),
  },
  {
    path: '/node',
    name: 'node',
    meta: {
      title: '选择投票节点'
    },
    component: importFile('exchange/Node'),
  },
  {
    path: '/success',
    name: 'success',
    meta: {
      title: ''
    },
    component: importFile('exchange/Success'),
  },
  {
    path: '/record',
    name: 'record',
    component: importFile('exchange/Record'),
  },
  {
    path: '/note',
    name: 'note',
    component: importFile('mine/Note'),
  },
  {
    path: '/more',
    name: 'more',
    component: importFile('mine/More'),
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      title: '团队账户'
    },
    component: importFile('mine/Team'),
  },

  {
    path: '/asset',
    name: 'asset',
    component: importFile('asset/Asset'),
  },
  {
    path: '/daily',
    name: 'daily',
    component: importFile('asset/Daily'),
  },
  {
    path: '/mine',
    name: 'mine',
    component: importFile('mine/Mine'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: importFile('mine/Contact'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: importFile('mine/Recharge'),
  },
  {
    path: '/invite',
    name: 'invite',
    component: importFile('invite/Invite'),
  },
  {
    path: '/inviteMore',
    name: 'inviteMore',
    component: importFile('invite/More'),
  },
  {
    path: '/message',
    name: 'message',
    component: importFile('mine/Message'),
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: importFile('mine/Pdf'),
  },
  {
    path: '/lock',
    name: 'lock',
    component: importFile('lock/Lock'),
  },
  {
    path: '/airdrop',
    name: 'airdrop',
    component: importFile('lock/AirDrop'),
  },
  {
    path: '/nft',
    name: 'nft',
    component: importFile('nft/Nft'),
  },
  {
    path: '/nftTab',
    name: 'nftTab',
    component: importFile('nft/NftTab'),
  },
  {
    path: '/compose',
    name: 'compose',
    component: importFile('nft/Compose'),
  },
  {
    path: '/mynft',
    name: 'mynft',
    component: importFile('nft/MyNft'),
  },
  {
    path: '/nftdetail',
    name: 'nftdetail',
    component: importFile('nft/NftDetail'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
