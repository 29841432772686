<template>
  <div class="HeaderNav" v-if="showType != 3">
    <div class="hn-main" v-if="showType == 1">
      <div class="hnm-logo">
        <img class="hnm-logo-img" v-if="systemInfo.logo" :src="systemInfo.logo" alt="">
        <!-- <div class="hnm-logo-text">{{systemInfo.webname}}</div> -->
      </div>
      <div class="hnm-btn">
        <CommonBtn :btnText='btnText' @btnClick="openCWallet"></CommonBtn>
      </div>
    </div>
    <div class="hn-nav" v-else>
      <img class="hn-back" @click="back" src="@/assets/images/icon_Return.png" alt="">
      <div class="hn-title">{{showTitle}}</div>
      <div class="hn-back2"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'HeaderNav',
  computed: {
    ...mapState({
      account: state => state.wallet.account,
      systemInfo: state => state.system.systemInfo
    }),
    btnText() {
      let text = this.$t('common.link')
      if (this.account) {
        text = this.account.slice(0, 4) + '....' + this.account.slice(-4)
      }
      return text
    }
  },
  data() {
    return {
      showType: 1,
      rightItem: 0, // 右上角操作项
      showTitle: ''
    }
  },
  watch: {
    $route(to, from){
        console.log('路由变化了')
        console.log('当前页面路由：');
        console.log(to);
        console.log('上一个路由：');
        console.log(from);
        let pages = ['/home']
        let noShow = ['/success', '/asset', '/mine', '/lock', '/airdrop', '/nftTab', '/compose', '/mynft', '/nftdetail']
        // 不是tab页面，显示返回栏
        if (noShow.indexOf(to.path) != -1) {
          this.showType = 3
        } else if (pages.indexOf(to.path) == -1) {
          this.showType = 2
          this.showTitle = to.meta.title
        } else {
          this.showType = 1
        }
        console.log(this.showType)
    },
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    openCWallet() {
      this.$emit('openWallet', this.account)
    },
    openDraw() {
      this.$emit('openDraw')
    },
  }
}
</script>

<style lang="scss" scoped>
.HeaderNav {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  .hn-main {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    background: url(~@/assets/images/bg_home.png);
    background-size: 100% auto;
    .hnm-icon {
      width: 16px;
      height: 12px;
    }
    .hnm-logo {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      .hnm-logo-img {
        width: 38px;
        height: 38px;
        margin-right: 7px;
        border-radius: 50%;
      }
      .hnm-logo-text {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;

        color: #FFFFFF;
      }
    }
    .hnm-btn {
      width: 115px;
      height: 32px;
    }
  }
  .hn-nav {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    background: url(~@/assets/images/bg_home.png);
    background-size: 100% auto;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #BDBDBD;
  }
  .hn-back {
    width: 25px;
    height: 25px;
    box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
    border-radius: 30px;
  }
  .hn-back2 {
    width: 25px;
    height: 25px;
  }
  .hn-right {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #EDC67F;
  }
}
</style>
