import zh_hk from './zh_hk.js'
import en_us from './en_us.js'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
	locale: localStorage.getItem('XELanguage') || 'zh_hk',
	legacy: false,
	messages: {
		zh_hk: zh_hk,
		en_us: en_us,
	}
})

export default i18n