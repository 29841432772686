<template>
  <van-popup 
    v-model:show="visible"
    :close-on-click-overlay="false"
    :lock-srcoll="false"
    round
    class="FitstLogin"
  >
    <div class="content">{{forbiddenText}}</div>
  </van-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'FitstLogin',
  components: {
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      forbiddenText: state => state.user.forbiddenText,
    }),
  },
  methods: {
    ...mapActions(['SetToken', 'GetUserInfo']),
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
  }
}
</script>

<style lang="scss" scoped>
:root {
  --van-popup-round-border-radius: 40px;
}
.FitstLogin {
  border-radius: 40px;
  background: #292D32;
  .content {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 90vh;
    padding: 15px 18px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
    // border-radius: 40px;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    white-space: pre-line;
    color: #FFFFFF;
  }
}
</style>
