import request from '@/http/request.js'

// 1.获取系统配置(/system/getinfo)
export function GetInfo(data) {
    return request('/api/system/getinfo', data)
}

// 6.首页(/index/index)
export function IndexInfo(data) {
    return request('/api/index/index', data)
}

// 8.首页-质押套餐列表(/index/pledgelist)
export function PledgeList(data) {
    return request('/api/index/pledgelist', data)
}

// 20.首页-获取内容说明(/index/getrole)
export function GetRule(data) {
    return request('/api/index/getrule', data)
}

// 7.首页-质押信息(/index/tokeninfo)
export function TokenInfo(data) {
    return request('/api/index/tokeninfo', data)
}

// 8.首页-节点列表(/index/nodelist)
export function NodeList(data) {
    return request('/api/index/nodelist', data)
}

// 19.理财包列表(/index/assetpackage)
export function AssetPackage(data) {
    return request('/api/index/assetpackage', data)
}

// 锁仓
// export function GetAbaddrArr(data) {
//     return request('/api/user/getabaddrarr', data)
// }
