<template>
  <van-popup
    v-model:show="visible"
    position="bottom"
    round
    class="WalletContent"
  >
    <div class="content">
      <div class="header">
        <div class="header-left" v-if="account">
          <div class="header-text">{{userInfo.nickname}}</div>
        </div>
        <div class="header-text" v-else>{{$t('wallet.chooseW')}}</div>
        <img class="header-icon" @click="close" src="@/assets/images/icon_Delete.png" alt="">
      </div>
      <!-- 未链接钱包 -->
      <template v-if="!account">
        <div class="main">
          <div class="main-item" v-for="(o, i) in walletList" :key="i" @click.stop="linkWallet(i)">
            <img class="item-logo" :src="o.logo" alt="">
            <div class="item-text">{{o.name}}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="detail">
          <div class="address">{{account}}</div>
          <div class="symbol"></div>
          <div class="title">{{systemInfo.tkname}} {{$t('wallet.asset')}}</div>
          <div class="balance">
            <div class="balance-key">{{$t('wallet.walletAccount')}}</div>
            <div class="balance-value">{{mudBalance || '-'}}</div>
          </div>
          <div class="balance">
            <div class="balance-key">{{$t('wallet.netwoekAccount')}}</div>
            <div class="balance-value">{{networkTotal || '-'}}</div>
          </div>
          <div class="balance">
            <div class="balance-key">{{$t('wallet.miningAccount')}}</div>
            <div class="balance-value">{{miningTotal || '-'}}</div>
          </div>
          <div class="btnArr">
            <div class="btnItem">
              <CommonBtn :btnText="$t('wallet.assetDesc')" @btnClick="toAsset"></CommonBtn>
            </div>
            <div class="btnItem">
              <CommonBtn :btnText="$t('common.myPledge')" :btnType="2" @btnClick="toRecord"></CommonBtn>
            </div>
          </div>
        </div>
      </template>
    </div>
    <van-popup 
      v-model:show="nameVisible"
      round
      class="EditContent"
    >
      <div class="content editContent">
        <div class="header">
          <div class="header-text">{{$t('wallet.editW')}}</div>
          <img class="header-icon" @click="editClose" src="@/assets/images/icon_Delete.png" alt="">
        </div>
        <div class="input">
          <input v-model="nickname" type="text" :placeholder="$t('toast.name')">
        </div>
        <div class="edit-btn">
          <CommonBtn :btnText="$t('common.save')" :loading="btnLoading" @btnClick="save"></CommonBtn>
        </div>
      </div>
    </van-popup>
  </van-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EditNickname } from '@/api/user'

export default {
  name: 'WalletContent',
  components: {
  },
  data() {
    return {
      visible: false,
      walletList: [
        {
          logo: require('@/assets/images/icon_tokenpocket.png'),
          name: 'TokenPocket'
        },
        {
          logo: require('@/assets/images/icon_Binance.png'),
          name: 'Binance Chain'
        },
        {
          logo: require('@/assets/images/icon_Metamask.png'),
          name: 'MetaMask'
        }
      ],
      nameVisible: false,
      nickname: '',
      btnLoading: false,
    }
  },
  watch: {
    showWallet() {
      if (this.showWallet) {
        this.SetShowWallet(false)
        this.show()
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.wallet.account,
      userInfo: state => state.user.userInfo,
      systemInfo: state => state.system.systemInfo,
      showWallet: state => state.system.showWallet,
      mudBalance: state => state.wallet.mudBalance,
      angleNum: state => state.wallet.angleNum,
      abNum: state => state.wallet.abNum,
      pledgeNum: state => state.wallet.pledgeNum,
      miningExtracted: state => state.wallet.miningExtracted,
    }),
    networkTotal() {
      // console.log('networkTotal', this.angleNum[0], this.angleNum[1], this.abNum[0], this.abNum[1])
      if (this.angleNum[1] == '-' || this.abNum[1] == '-') {
        return '-'
      } else {
        return this.angleNum[1] + this.abNum[1] + ''
      }
    },
    miningTotal() {
      if (this.miningExtracted == '-' || this.pledgeNum[1] == '-') {
        return '-'
      } else {
        return (+this.miningExtracted) + this.pledgeNum[1] + ''
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions(['InitWallet', 'GetUserInfo', 'SetBalance', 'SetShowWallet']),
    show() {
      this.visible = true
      if (this.account) {
        // this.SetBalance()
        this.GetUserInfo()
      } else {
        this.InitWallet().then(() => {
          this.visible = false
        }).catch(() => {
          this.visible = true
        })
      }
    },
    close() {
      this.visible = false
    },
    linkWallet() {
      this.InitWallet().then(() => {
        this.visible = false
      })
    },
    editShow() {
      this.nickname = this.userInfo.nickname
      this.nameVisible = true
    },
    editClose() {
      this.nameVisible = false
    },
    save() {
      let regex=/^[0-9a-zA-Z]+$/;
      if (regex.test(this.nickname) && (this.nickname.length >= 4 && this.nickname.length <= 12)) {
        this.btnLoading = true
        EditNickname({nickname: this.nickname}).then(() => {
          this.$toast(this.$t('toast.editSuccess'))
          this.btnLoading = false
          this.GetUserInfo()
          this.nameVisible = false
        }).catch(() => {
          this.btnLoading = false
        })
      } else {
        this.$toast(this.$t('toast.name'))
      }
    },
    toRecord() {
      this.visible = false
      this.$router.push('record')
    },
    toAsset() {
      this.visible = false
      this.$router.push('asset')
    },
  }
}
</script>

<style lang="scss" scoped>
:root {
  --van-overlay-background-color: rgba(0, 0, 0, .4) ;
}
.WalletContent {
  border-radius: 40px 40px 0px 0px;
  .content {
    padding: 15px 22px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.66);

  }
  .header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .header-left {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .header-role {
      margin-left: 12px;
      padding: 2px 14px;
      background: $color-gradient;
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
      font-weight: 500;
    }
    .header-text {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
    }
    .header-icon {
      width: 16px;
      height: 16px;
    }
  }
  .main {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-item {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin: 5px 0;
      padding: 10px 24px;
      background: #F2F3F9;
      border-radius: 8px 8px 8px 8px;
      .item-logo {
        width: 38px;
        height: 38px;
        margin-right: 32px;
        border-radius: 5px 5px 5px 5px;
      }
      .item-text {
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #000000;
        line-height: 19px;
      }
    }
  }
  .detail {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .address {
      width: 100%;
      text-align: left;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #B8B6B2;
    }
    .symbol {
      width: 29px;
      height: 6px;
      margin: 16px 0;
      margin-right: auto;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    .title {
      margin-right: auto;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
    .name {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #362B45;
      line-height: 16px;
      .name-icon {
        width: 11px;
        height: 11px;
        margin-left: 10px;
      }
    }
    .balance {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 4px 0;
      .balance-key {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B8B6B2;
      }
      .balance-value {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
      }
    }
  }
  .editContent {
    width: 300px;
    height: 218px;
    .input {
      width: 100%;
      margin: 28px 0;
      input {
        width: 256px;
        height: 48px;
        padding: 0 16px;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #F1F1F4;
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: #40384A;
        line-height: 16px;
      }

    }
    .edit-btn {
      width: 256px;
      height: 40px;
    }
  }
  .btnArr {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0 20px 0;
    .btnItem {
      width: 160px;
      height: 40px;
    }
  }
}
</style>
