import axios from 'axios'
import store from '@/store'
import {
  Toast
} from 'vant'
import router from '@/router'
import md5 from '@/utils/md5'
import QS from 'qs'
// import { useI18n } from 'vue-i18n'
import i18n from '../lang'
const { t } = i18n.global
/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
  Toast({
    message: msg,
    duration: 1000,
    forbidClick: true
  })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  switch (status) {
    // 1000:参数错误
    case 1000:
      tip(t('request.paramError'))
      break
    case 1001:
      tip(t('request.dataError'))
      break
    case 1002:
      tip(t('request.signError'))
      break
    case 1003:
      tip(t('request.nodata'))
      break
    case 20005:
      router.push({
        path: '/home'
      })
      store.dispatch('Logout')
      store.dispatch('SetIsForbidden', true)
      store.dispatch('setForbiddenText', other)
      // tip(other)
      break
    default:
      tip(other)
  }
}

var instance = axios.create({
  timeout: 1000 * 60
})
instance.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'
instance.defaults.withCredentials = true

instance.interceptors.response.use(
  res => {
    if (res.status === 200) {
      let flag = parseInt(res.data.flag)
      if (flag === 200) {
        store.dispatch('SetIsForbidden', false)
        return Promise.resolve(res.data)
      } else if (flag === 1004) {
        router.push({
            path: '/home'
        })
      } else {
        errorHandle(flag, res.data.data)
        return Promise.reject(res.data)
      }
    } else {
      return Promise.reject(res.data)
    }
  },
  error => {
    const {
      response
    } = error
    if (response) {
      return Promise.reject(response)
    } else {
      return Promise.reject(t('request.noNetwork'))
    }
  }
)

/**
 * 去除对象中为空或者类型为undifend的属性
 * @param {Object} params 
 */
function paramRemoveUndenfind (params) {
  for (let prop in params) {
    if ((params[prop] === '' || !params[prop]) && params[prop]!='0') {
      delete params[prop]
    }
  }
  if (JSON.stringify(params) === '{}') {
    params = undefined
  }
  return params
}

export default function post (url, params) {
  // console.log(useI18n().t('request.paramError'))
  return new Promise((resolve, reject) => {
    params = params ? paramRemoveUndenfind(params) : params
    let date = new Date().format('Ymd')
    let language = localStorage.getItem('XELanguage') || ''
    let token = localStorage.getItem('XEToken') || ''
    let sign = md5(params ? JSON.stringify(params) + date + "$2022%^dapp" + language : date + "$2022%^dapp" + language)
    let data = params ? Object.assign({
      date: date,
      sign: sign,
      language: language,
      token: token
    }, {
      param: JSON.stringify(params)
    }) : {
      date: date,
      sign: sign,
      language: language,
      token: token
    }
    instance.post(url, QS.stringify(data))
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}