<template>
  <van-popup 
    v-model:show="visible"
    :close-on-click-overlay="false"
    :lock-srcoll="false"
    round
    class="TrxNotice"
  >
    <div class="content">
      MUD已从波场映射至以太坊，请使用ETH钱包打开
    </div>
  </van-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'TrxNotice',
  components: {
  },
  data() {
    return {
      visible: false,
      nickname: '',
      icode: '',
      btnLoading: false,
      notice: false,
    }
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      account: state => state.wallet.account,
      usdtBalance: state => state.wallet.usdtBalance,
      ethBalance: state => state.wallet.ethBalance,
    }),
  },
  methods: {
    ...mapActions(['']),
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
  }
}
</script>

<style lang="scss" scoped>
:root {
  --van-popup-round-border-radius: 40px;
}
.TrxNotice {
  border-radius: 40px;
  .content {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 320px;
    height: 160px;
    padding: 15px 18px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
    // border-radius: 40px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #B8B6B2;
  }
  
}
</style>
