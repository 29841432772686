<template>
  <div class="CommonBtn-main">
    <van-button 
      class="CommonBtn"
      :class="{'CommonBtn': btnType == 0, 'type2': btnType == 2,'type3': btnType == 3, 'btnDisabled': disabled,}"
      @click="btnClick"
      :loading="loading"
      icon-position="right"
    >
    </van-button>
    <div :class="{'CommonBtn-in': btnType == 0}"></div>
    <div :class="{'CommonBtn-text': btnType == 0, 'type2-text': btnType == 2 || btnType == 3}" @click="btnClick">{{btnText}}</div>
  </div>
  
</template>

<script>
export default {
  name: 'CommonBtn',
  props: {
    btnText: {
      type: String,
      default: ''
    },
    btnIcon: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    btnType: {
      type: Number,
      default: 0
    }
  },
  computed: {
    btnIconUrl() {
      return require(`@/assets/images/${this.btnIcon}.png`)
    }
  },
  methods: {
    btnClick() {
      this.$emit('btnClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.CommonBtn-main {
  position: relative;
  height: 100%;
  width: 100%;
}
.CommonBtn {
  position: relative;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(320.66deg, #F5EBCB 14.75%, #EDC67F 84.81%);
  filter: blur(0.5px);
  border-radius: 18px;
  transform: rotate(-180deg);
  // box-shadow: 2px 3px 8px #000000, -2px -3px 8px #2F393D;
  .cb-img {
    height: 25%;
    margin-left: 6px;
  }
  
}
.CommonBtn-in {
  position: absolute;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: linear-gradient(320.66deg, #EFB956 14.75%, #EDC67F 84.81%);
  filter: blur(0.5px);
  // border: 1px solid #C0851A; 
  border-radius: 18px;
  transform: rotate(-180deg);
}
.type2 {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% + 4px);
  color: #C7C7C7;
  background: #292D32;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
  border-radius: 20px;
  border: 1px solid #292D32;
  .cb-img {
    height: 25%;
    margin-left: 6px;
  }
}
.type3 {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% + 4px);
  color: #C7C7C7;
  background: #292D32;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
  border-radius: 20px;
  border: 1px solid #292D32;
  .cb-img {
    height: 25%;
    margin-left: 6px;
  }
}
.CommonBtn-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -40%);
  width: 100%;
  text-align: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #75521A;
}
.type2-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #C7C7C7;
}
.btnDisabled {
  background: #C4C4C4;
}
</style>
