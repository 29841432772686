import { UserExist, LoginV2, Logout, UserInfo } from "@/api/user"
// import md5 from '@/utils/md5'
const user = {
  state: {
    token: localStorage.getItem('XEToken') || '', // 
    userInfo: {}, // 用户信息
    firstLogin: false, // 是否第一次登陆
    isForbidden: false, // 用户是否禁用
    forbiddenText: '', //
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_FIRSTLOGIN: (state, firstLogin) => {
      state.firstLogin = firstLogin
    },
    SET_ISFORBIDDEN: (state, isForbidden) => {
      state.isForbidden = isForbidden
    },
    SET_FORBIDDENTEXT: (state, forbiddenText) => {
      state.forbiddenText = forbiddenText
    },
  },
  actions: {
    async Login({dispatch}, account){
      let username = account
      if (!username) return
      dispatch('SetMaskLoading', true)
      await UserExist({username: username}).then(async res => {
        // true 已注册  false 未注册
        
        if (res.status) {
          dispatch('SetLoadingText', 'Confirmation signature')
          
          let sign = ''
          dispatch('SetToken', '')
          let loading = setInterval(() => {
            dispatch('SetMaskLoading', true)
          }, 500);
          await dispatch('signVerify').then(sres => {
            sign = sres
            if (sign) {
              clearInterval(loading)
              dispatch('SetMaskLoading', false)
              let param = {
                username: username,
                password: sign,
              }
              LoginV2(param).then(lres => {
                dispatch('SetToken', lres.token)
                dispatch('GetUserInfo')
              })
              if (!res.eth) {
                // dispatch('SetFirstLogin', true)
              }
            } else {
              dispatch('SetLoadingText', 'Please refresh and retry')
            }
          })
          
          
          
        } else {
          dispatch('SetFirstLogin', true)
        }
      })
    },
    Logout({commit}) {
      Logout().then(() => {
        commit('SET_TOKEN', '')
        localStorage.setItem('XEToken', '')
      })
    },
    GetUserInfo({commit}) {
      UserInfo().then(res => {
        commit('SET_USERINFO', res)
      })
    },
    SetToken({commit}, token) {
      commit('SET_TOKEN', token)
      localStorage.setItem('XEToken', token)
    },
    SetFirstLogin({commit}, firstLogin) {
      commit('SET_FIRSTLOGIN', firstLogin)
    },
    SetIsForbidden({commit}, isForbidden) {
      commit('SET_ISFORBIDDEN', isForbidden)
    },
    setForbiddenText({commit}, forbiddenText) {
      commit('SET_FORBIDDENTEXT', forbiddenText)
    },
  }
}

export default user
