// import { Toast } from 'vant'
import i18n from '../../lang'
const { t } = i18n.global
import { NetworkAccount } from "@/api/user"
import coinAbi from '@/utils/coinAbi'
import angleAbi from '@/utils/angleAbi'
import abAbi from '@/utils/abAbi'
import teamAbi from '@/utils/teamAbi'
import pledgeAbi from '@/utils/pledgeAbi'
import miningAbi from '@/utils/miningAbi'
// import fractionAbi from '@/utils/fractionAbi'
// import commonAnimalAbi from '@/utils/commonAnimalAbi'
// import rareAnimalAbi from '@/utils/rareAnimalAbi'
// import epicAnimalAddrAbi from '@/utils/epicAnimalAddrAbi'
import upgradeAbi from '@/utils/upgradeAbi'
import Web3 from "web3"
let ethUtil = require('ethereumjs-util')

const wallet = {
  state: {
    isLine: '', // 是否上线1是 1走合约 2否 2不走合约
    account: '', // 钱包地址
    usdtBalance: '-', // 当前钱包usdt余额
    ethBalance: '-', // 当前钱包usdt余额
    mudBalance: '-', // 当前钱包代币余额
    decimals: 6, // 精度
    // matic主链
    usdtAddr: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // usdt
    mudAddr: '0xf6EaC236757e82D6772E8bD02D36a0c791d78C51', // 代币合约地址
    swapAddr: '0xf6fc99980aD3Df6D67e08E482a427BE2B7461a33', // 流动性锁仓合约地址
    poolAddr: '0x5338968F9646e4A865D76e07C2A6E340Dd3aC462', // 流动性底池
    quoterAddr: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', // Quoter 报价合约地址
    angleAddr: '0xB78d353042596448ef067Dad0f92EaA9373FDA7D', // 天使轮
    abAddr: '0xf628f33a9174dde7100249A84800eE849139b1A2', // AB轮
    teamAddr: '0x23fa4b73F80fbB1563792662C434D307bF49b224', // 团队
    pledgeAddr: '0xf1Ff4342EcD100213Ab3FbbdbC13EF84C77ef38A', // 抵押挖矿
    miningAddr: '0x35E53d176723b7516C559634181bcEad354c93F1', // 矿池合约
    airAddr: '0x09191D00a223a6500d71d6A6492D36C31dFE9E47', // 空投
    upgradeAddr: '0xB8D9A7E5DE07Aee3332338732c5c8313DEc19EC9', // NFT Upgrade contract
    upgradeLegendAddr: '0x8D345aB6fe9360ae94b6E53dBBE0d5F88f45731D', // 传奇 NFT Upgrade contract
    fractionAddr: '0x0fA0C5543bA44D09AF7F2A0797a984706f3AC56A', // 基本
    commonAnimalAddr: '0xaaf4cfE99B965112E98E31e26E2212F33710f14F', // 普通（白银）
    rareAnimalAddr: '0x5393D021fa6D70f842BE3062Dcc6DcF4f073d790', // 稀有（黄金）
    epicAnimalAddr: '0x082e73b25403FE83D8eF085dD9eacB2360B4Ef96', // 史诗（紫金）
    legendAnimalAddr: '0xc142C323A9a11DC4498A49B942D09AA6fcB9135e', // 传奇
    // matic测试
    // usdtAddr: '0x1ef964b77efA568adf820f220e5Af672254F6531', // usdt
    // mudAddr: '0xD915133a0452d28832A4365770207eb5863a0fF2', // 代币合约地址 nft测试
    // swapAddr: '0xd305801432511f4F61B014C9ff95cfeb48AA7BE3', // 流动性锁仓合约地址
    // poolAddr: '0x5f852454ec0D844E4AE0565eEB127cb37aC2A13b', // 流动性底池
    // quoterAddr: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', // Quoter 报价合约地址
    // angleAddr: '0xB78d353042596448ef067Dad0f92EaA9373FDA7D', // 天使轮
    // abAddr: '0xf628f33a9174dde7100249A84800eE849139b1A2', // AB轮
    // teamAddr: '0x23fa4b73F80fbB1563792662C434D307bF49b224', // 团队
    // pledgeAddr: '0xf1Ff4342EcD100213Ab3FbbdbC13EF84C77ef38A', // 抵押挖矿
    // miningAddr: '0x35E53d176723b7516C559634181bcEad354c93F1', // 矿池合约
    // airAddr: '0x09191D00a223a6500d71d6A6492D36C31dFE9E47', // 空投
    // upgradeAddr: '0x8A5e78C09316152eFf52ebD43058708BFb8A1292', // NFT Upgrade contract
    // upgradeLegendAddr: '0x8b2eff65a3594a0883AdfBCb33f248b1Ddf94390', // 传奇 NFT Upgrade contract
    // fractionAddr: '0x9cAbb47d92d76565B870Bc3565F9689699D143ED', // 基本
    // commonAnimalAddr: '0x4c5509098eb1Ce18304B8F3801EDD432F3bf3571', // 普通（白银）
    // rareAnimalAddr: '0x3C24238CA9771eaCF060597a7d7eC6706dFdDE61', // 稀有（黄金）
    // epicAnimalAddr: '0x1b7A137E201775cE4CA6c803BC1A185caE779514', // 史诗（紫金）
    // legendAnimalAddr: '0xC337802691B7d03B96E5A038020F0b26EC76639f', // 传奇
    // [可释放约, 锁仓总额]
    angleNum: ['-', '-'],
    abNum: ['-', '-'],
    teamNum: ['-', '-'],
    pledgeNum: ['-', '-'],
    // 
    miningExtracted: '-', // 矿池合约可提取数额
  },
  mutations: {
    SET_ISLINE: (state, isLine) => {
      state.isLine = isLine
    },
    SET_ACCOUNT: (state, account) => {
      state.account = account
    },
    SET_USDT_BALANCE: (state, usdtBalance) => {
      state.usdtBalance = usdtBalance
    },
    SET_MATIC_BALANCE: (state, ethBalance) => {
      state.ethBalance = ethBalance
    },
    SET_MUD_BALANCE: (state, mudBalance) => {
      state.mudBalance = mudBalance
    },
    SET_BALANCE: (state, mudBalance) => {
      state.mudBalance = mudBalance
    },
    SET_NUM:  (state, obj) => {
      switch(+obj.type) {
        case 1:
          state.angleNum = obj.num
          break
        case 2:
          state.abNum = obj.num
          break
        case 3:
          state.teamNum = obj.num
          break
        case 4:
          state.pledgeNum = obj.num
          break
      }
    },
    SET_MINING_EXT: (state, miningExtracted) => {
      state.miningExtracted = miningExtracted
    },
  },
  actions: {
    SetIsLine({commit}, isLine) {
      commit('SET_ISLINE', isLine)
    },
    // 判断连接类型  1 TRC20  2 BSC
    async InitWallet ({state, dispatch}) {
      dispatch('SetMaskLoading', true)
      dispatch('SetLoadingText', t('toast.linkWallet'))

      let timer = null
      let flag = 0
      console.log('isLine', state.isLine)
      if (window.ethereum) {
        timer = setInterval(() => {
          flag ++
          window.ethereum.enable().then((res) => {
            flag = 0
            let address = res[0]
            if (address != state.account) {
              dispatch('SetMaskLoading', false)
              dispatch('SetAccount', address)
            }
          }).catch(err => {
            console.log(err)
            dispatch('SetMaskLoading', false)
          })
          console.log(flag)
          if (flag > 20) {
            clearInterval(timer)
          }
        }, 1000)
      } else if (window.tronWeb) {
        dispatch('SetTrxNotice', true)
        dispatch('SetMaskLoading', false)
      } else {
        // dispatch('SetMaskLoading', false)
        // Toast(t('toast.usetrc'))
        dispatch('SetLoadingText', t('toast.usetrc'))
      }
    },
    SetAccount({commit, dispatch}, account) {
      dispatch('actionTodo')
      commit('SET_ACCOUNT', account)
      if (account) {
        dispatch('Login', account)
        dispatch('SetBalance')
      }
    },
    async SetBalance({state, commit, dispatch}) {
      dispatch('SetMaskLoading', true)
      dispatch('SetLoadingText', t('getBalance'))

      const address = state.account
      const usdtAddr = state.usdtAddr
      const mudAddr = state.mudAddr
      let decimals = state.decimals

      let ethereum = window.ethereum
      try {
        // 获取当前主链余额
        await ethereum.request({ 
          method: 'eth_getBalance',
          params: [address, "latest"]
        })
        .then(res => {
          let result = res / Math.pow(10, 18)
          console.log('Balance = ', res, ',', result)
          commit('SET_MATIC_BALANCE', result || '0')
        })
        .catch((error) => {
          console.log(error)
        })

        // usdt
        // data数据格式：最前边的“0x70a08231000000000000000000000000”是固定的，后边的是钱包地址（不带“0x”前缀）
        let str = address.slice(2, address.length)
        let paramData = '0x70a08231000000000000000000000000' + str
        await ethereum.request({ 
          method: 'eth_call',
          params: [{
            from: address,
            to: usdtAddr,
            data: paramData
          }, 'latest']
        })
        .then(res => {
          let result = res / Math.pow(10, 6)
          console.log('UsdtBalance = ', res, ',', result)
          result = result.toFixed(5)
          commit('SET_USDT_BALANCE', result || '0')
          dispatch('SetMaskLoading', false)
        })
        // .catch((error) => {
        //   console.log(error)
        //   dispatch('SetMaskLoading', false)
        // })
        
        // mud
        await ethereum.request({ 
          method: 'eth_call',
          params: [{
            from: address,
            to: mudAddr,
            data: paramData
          }, 'latest']
        })
        .then(res => {
          let result = res / Math.pow(10, decimals)
          console.log('MudBalance = ', res, ',', result)
          commit('SET_MUD_BALANCE', result || '0')
          dispatch('SetMaskLoading', false)
        })
        .catch((error) => {
          console.log(error)
          dispatch('SetMaskLoading', false)
        })
      } catch(error) {
        console.error("trigger smart contract error", error)
        // dispatch('SetBalance')
      }
      
      dispatch('SetMaskLoading', false)
    },
    // 查询当前账户可释放余额
    async checkBalance({state, commit, dispatch}, type = 0) {
      // type 0全部查询 1天使 2AB 3团队 4 抵押挖矿
      dispatch('SetMaskLoading', true)
      dispatch('SetLoadingText', t('common.loading'))
      console.log('checkBalance类型', type)
      if (state.isLine == 1) {
        const address = state.account
        const web3 = new Web3(window.ethereum)

        let contractAbi = {}
        let contractAddress = ''

        let decimals = state.decimals
        let myContract = ''
        // let balance = ''
        let a = 0, b = 0, c = 0, d = 0
        if (type == 0 || type == 1) {
          // 天使轮
          contractAbi = angleAbi
          contractAddress = state.angleAddr
          myContract = new web3.eth.Contract(contractAbi, contractAddress, {
            from: address, 
          });
          myContract.methods.checkBalance(address).call((error, result) => {
            console.log('天使轮', error, result)
            // Toast(`天使轮,${error},${JSON.stringify(result)}`)
            a = result[0]
            b = a / Math.pow(10, decimals)
            c = result[1]
            d = c / Math.pow(10, decimals)
            console.log('天使轮可释放余额', b)
            console.log('天使轮锁仓总额', d)
            commit('SET_NUM', {type: 1, num: [b, d]})
          });
        } 
        if (type == 0 || type == 2) {
          // ab轮
          contractAbi = abAbi
          contractAddress = state.abAddr
          myContract = new web3.eth.Contract(contractAbi, contractAddress, {
            from: address, 
          });
          myContract.methods.checkBalance(address).call((error, result) => {
            console.log('ab轮', error, result)
            a = result[0]
            b = a / Math.pow(10, decimals)
            c = result[1]
            d = c / Math.pow(10, decimals)
            console.log('ab轮可释放余额', b)
            console.log('ab轮锁仓总额', d)
            commit('SET_NUM', {type: 2, num: [b, d]})
          });
        } 
        if (type == 0 || type == 3) {
          // 团队
          contractAbi = teamAbi
          contractAddress = state.teamAddr
          myContract = new web3.eth.Contract(contractAbi, contractAddress, {
            from: address, 
          });
          myContract.methods.checkBalance(address).call((error, result) => {
            console.log('团队', error, result)
            a = result[0]
            b = a / Math.pow(10, decimals)
            c = result[1]
            d = c / Math.pow(10, decimals)
            console.log('团队可释放余额', b)
            console.log('团队锁仓总额', d)
            commit('SET_NUM', {type: 3, num: [b, d]})
          });
        }  
        if (type == 0 || type == 4) {
          // 抵押挖矿
          contractAbi = pledgeAbi
          contractAddress = state.pledgeAddr
          myContract = new web3.eth.Contract(contractAbi, contractAddress, {
            from: address, 
          });
          myContract.methods.checkBalance(address).call((error, result) => {
            console.log('抵押挖矿', error, result)
            a = result[0]
            b = a / Math.pow(10, decimals)
            c = result[1]
            d = c / Math.pow(10, decimals)
            console.log('抵押挖矿可释放余额', b)
            console.log('抵押挖矿锁仓总额', d)
            commit('SET_NUM', {type: 4, num: [b, d]})
          });
        }
      } else {
        NetworkAccount().then(res => {
          commit('SET_NUM', {type: 1, num: [0, +res.ts]})
          commit('SET_NUM', {type: 2, num: [0, +res.ab]})
          commit('SET_NUM', {type: 3, num: [0, +res.team]})
          commit('SET_NUM', {type: 4, num: [0, 0]})
        })
      }
      
      dispatch('SetMaskLoading', false)
    },
    // 矿池合约 可提取数额
    async miningCheckBalance({state, commit, dispatch}) {
      dispatch('SetMaskLoading', true)
      dispatch('SetLoadingText', t('common.loading'))
      if (state.isLine == 1) {
        const web3 = new Web3(window.ethereum)
        const address = state.account

        let decimals = state.decimals
        let contractAbi = miningAbi
        let contractAddress = state.miningAddr
        let a = 0, b = 0
        let myContract = ''

        myContract = new web3.eth.Contract(contractAbi, contractAddress, {
          from: address,
        });
        // 开始挖矿
        // myContract.methods.miningStart()
        //   .send((error, result) => {
        //     console.log('矿池', error, result)
        //   });
        myContract.methods.checkBalance(address)
          .call((error, result) => {
            console.log('矿池', error, result)
            a = result
            b = a / Math.pow(10, decimals)
            console.log('矿池可提取余额', b)
            commit('SET_MINING_EXT', b || '0')
          });
      } else {
        commit('SET_MINING_EXT', 0)
      }
      
      
      dispatch('SetMaskLoading', false)
    },
    // 钱包签名
    async signVerify({state},te) {
      return new Promise((resolve) => {
        const web3 = new Web3(window.ethereum)
        const address = state.account
        let text = te??'Please login first'
        let msg = ethUtil.bufferToHex(new Buffer(text, 'utf8'))
        let params = [msg, address]
        let method = 'personal_sign'

        web3.currentProvider.sendAsync({
          method,
          params,
          address
        }, (err, result) => {
          console.log('sendAsync', err, result)
          if (err) {
            resolve('')
          } else if (result.err) {
            console.log('result.err', result.err)
            resolve('')
          } else {
            let sign = result.result
            console.log('PERSONAL SIGNED:'+ sign)
            resolve(sign)
          }
        })
      })
      
    },
    // 查询当前拥有的nft
    async queryNft({state, dispatch}) {
      dispatch('SetMaskLoading', true)
      dispatch('SetLoadingText', t('common.loading'))
      const web3 = new Web3(window.ethereum)
      const address = state.account
      let contractAbi = ''
      let contractAddress = ''
      let decimals = state.decimals
      let myContract = ''
      
      contractAbi = coinAbi
      contractAddress = state.mudAddr
      myContract = new web3.eth.Contract(contractAbi, contractAddress, {
        from: address,
      });
      // 查询当前拥有的NFT
      myContract.methods.balanceOf(address).call((err, balance) => {
        let result = balance / Math.pow(10, decimals)
        if (err) {
          console.error('Error:', err);
        } else {
          console.log('当前拥有的NFT数量:', result);
        }
      });
      
      dispatch('SetMaskLoading', false)
    },
    // 合成nft
    async compoundNft({state, dispatch}, type = 0, id1, id2, id3) {
      // type 0 1基础升普通 2普通升稀有 3稀有升史诗
      dispatch('SetMaskLoading', true)
      dispatch('SetLoadingText', t('common.loading'))
      const address = state.account
      const web3 = new Web3(window.ethereum)

      let contractAbi = {}
      let contractAddress = ''

      let myContract = ''
      contractAbi = upgradeAbi
      contractAddress = state.upgradeAddr
      myContract = new web3.eth.Contract(contractAbi, contractAddress, {
        from: address, 
      });
      
      if (type == 0 || type == 1) {
        // 1基础升普通
        myContract.methods.GetCommonAnimal(id1, id2, id3).call((error, result) => {
          console.log('基础升普通', error, result)
        });
      }
      dispatch('SetMaskLoading', false)
    }
  }
}

export default wallet
