import { createApp } from 'vue'
import App from './App.vue'
// import Vue from 'vue'
import router from './router'
import store from './store'

// 时间formatjs
import './utils/date.format.js'

// 基础样式初始化
import '@/assets/css/base.css' 

// rem
import 'lib-flexible'
// import "./utils/rem"

// 多语言
import i18n from '@/lang' 

// bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

// vant ui
// 全引入
// import Vant from 'vant';
// import 'vant/lib/index.css';
// app.use(Vant);
// 按需
import { Toast, Button, Progress, Popup, Loading, Dialog, List, Picker, ActionSheet, Tabbar, 
  TabbarItem, Icon, Calendar, Swipe, SwipeItem, Lazyload } from 'vant';
import 'vant/lib/index.css';

// svg组件
// import '@/SvgIcon/index.js'
// import SvgIcon from '@/SvgIcon/index.vue'

// 通用按钮
import CommonBtn from '@/components/CommonBtn'

let app = createApp(App).use(store).use(router).use(i18n)
app.use(Toast).use(Button).use(Progress).use(Popup).use(Loading).use(Dialog)
  .use(List).use(Picker).use(ActionSheet).use(Tabbar).use(TabbarItem).use(Icon)
  .use(Calendar).use(Swipe).use(SwipeItem).use(Lazyload)
app.component('CommonBtn', CommonBtn)
// app.component('svg-icon', SvgIcon)
app.config.globalProperties.$bscdapp = null
app.mount('#app')
export default app