<template>
    <van-popup 
      v-model:show="visible"
      :close-on-click-overlay="true"
      :lock-srcoll="false"
      round
      class="FitstLogin"
    >
        <div class="content">
            <view class="title">{{ announceText["title"] }}</view>
            <img class="spreadimg" :src="announceText['logo']" alt="">
            <view class="subtitle">{{ announceText["intro"] }}</view>
            <div class="detailbtn">
                <CommonBtn :btnText="$t('common.detail')" @btnClick="goDetail"></CommonBtn>
            </div>
        </div>
    </van-popup>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'Announce',
    components: {
    },
    data() {
      return {
        visible: false,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system.systemInfo,
        announceText: state => state.system.announceText,
      }),
    },
    methods: {
      ...mapActions(['SetToken', 'GetUserInfo']),
      show() {
        this.visible = true
      },
      close() {
        this.visible = false
      },
      goDetail() {
        this.visible = false
        // 链接类型 1不跳转 2内链 3外链
        switch(this.announceText['type']){
            case '1':
                this.$router.push('/message?type=1&&id='+this.announceText['id'].toString())
                break;
            case '2':
                this.$router.push(this.announceText['url'])
                break;
            case '3':
                window.open(this.announceText['url'])
                break;
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  :root {
    --van-popup-round-border-radius: 40px;
  }
  .FitstLogin {
    border-radius: 40px;
    background: #292D32;
    .content {
        display:flex;
        flex-direction: column;
        width: 320px;
        //   height: 90vh;
        padding: 15px 18px;
        background: #292D32;
        box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
        // border-radius: 40px;
      
      .title {
        font-family: 'Alibaba PuHuiTi';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        white-space: pre-line;
        color: #FFFFFF;
      }
      .spreadimg {
        height: 125px;
        width: 274px;
        margin: 10px 0;
      }
      .subtitle {
        font-family: 'Alibaba PuHuiTi';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        white-space: pre-line;
        color: #FFFFFF;
      }
      .detailbtn {
        height: 40px;
        width: 308;
        margin-top: 25px;
      }
    }
    
  }
  </style>
  