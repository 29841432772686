<template>
  <van-popup 
    v-model:show="visible"
    round
    class="NoticeContent"
  >
    <div class="content">
      <img class="header-icon" @click="close" src="@/assets/images/icon_Delete.png" alt="">
      <div class="main" v-html="infoData.content"></div>
    </div>
  </van-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { GetRule } from '@/api/system'
export default {
  name: 'NoticeContent',
  components: {
  },
  data() {
    return {
      visible: false,
      infoData: {},
    }
  },
  computed: {
    ...mapState({
      noticeType: state => state.system.noticeType,
      systemInfo: state => state.system.systemInfo,
    }),
  },
  watch: {
    noticeType() {
      console.log(this.noticeType)
      if (this.noticeType != 0) {
        // 1项目介绍2用户协议3计算公式4如何加速5质押规则6推荐规则7数值说明
        // 8可质押数 9 挖矿账户 10 全网账户 11 我的质押可提取 12 团队账户
        if (this.noticeType <= 7) {
          GetRule({type: this.noticeType}).then(res => {
            this.infoData = res
            this.visible = true
          })
        } else if (this.noticeType == 8) {
          this.infoData = {
            content: this.$t('notice.text_1') + this.systemInfo.tkname + this.$t('notice.text_1_2')
          }
          this.visible = true
        } else if (this.noticeType == 9) {
          this.infoData = {
            content: `<div>${this.$t('notice.text_2')}</div>
              <div>${this.$t('notice.text_3')}</div>
              <div>${this.$t('notice.text_4')}</div>
              <div>${this.$t('notice.text_5')}</div>`
          }
          this.visible = true
        } else if (this.noticeType == 10) {
          this.infoData = {
            content:  `<div>${this.$t('notice.text_6')}</div>
              <div>${this.$t('notice.text_7')}</div>
              <div>${this.$t('notice.text_8')}</div>
              <div>${this.$t('notice.text_9')}</div>`
          }
          this.visible = true
        } else if (this.noticeType == 11) {
          this.infoData = {
            content:  `<div>${this.$t('notice.text_10')}</div>
              <div>${this.$t('notice.text_11')}；</div>
              <div>${this.$t('notice.text_12')}</div>`
          }
          this.visible = true
        } else if (this.noticeType == 12) {
          this.infoData = {
            content:  `<div>${this.$t('notice.text_13')}</div>`
          }
          this.visible = true
        }
        
      }
    },
    visible() {
      if(!this.visible) {
        this.setNoticeType(0)
      }
    }
  },
  methods: {
    ...mapActions(['setNoticeType']),
    close() {
      this.visible = false
    },
  }
}
</script>

<style lang="scss" scoped>
:root {
  --van-popup-round-border-radius: 40px;
}
.NoticeContent {
  border-radius: 40px;
  .content {
    position: relative;
    width: 320px;
    min-height: 200px;
    padding: 35px 18px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
    // border-radius: 40px;
  }
  .header-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
  }
  .main {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    color: #B8B6B2;
  }
}
</style>
