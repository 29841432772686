<template>
  <van-popup 
    v-model:show="visible"
    :close-on-click-overlay="false"
    :lock-srcoll="false"
    round
    class="FitstLogin"
  >
    <div class="content">
      <div class="header">
        <div class="header-text">{{$t('login.linked')}}</div>
      </div>
      <div class="detail">
        <div class="address">{{account}}</div>
        <div class="symbol"></div>
        <div class="balance">
          <img class="balance-logo" src="@/assets/images/icon_Account_Nav_sel.png" alt="">
          <div class="balance-key">{{systemInfo.tkname}}{{$t('common.balance')}}</div>
          <div class="balance-value">{{usdtBalance || '-'}}</div>
        </div>
        <div class="balance">
          <img class="balance-logo" src="@/assets/images/icon_Account_Nav_sel.png" alt="">
          <div class="balance-key">MATIC {{$t('common.balance')}}</div>
          <div class="balance-value">{{ethBalance || '-'}}</div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">{{$t('common.name')}}：</div>
        <div class="input">
          <input v-model="nickname" type="text" :placeholder="$t('toast.name')">
        </div>
      </div>
      <div class="item">
        <div class="item-title">{{$t('login.icode')}}：</div>
        <div class="input">
          <input v-model="icode" type="text" :placeholder="$t('toast.icode')">
        </div>
      </div>
      <div class="edit-btn">
        <CommonBtn :btnText="$t('common.save')" :loading="btnLoading" @btnClick="saveBefore"></CommonBtn>
      </div>
    </div>
    <van-popup 
      v-model:show="notice"
      :lock-srcoll="false"
      round
      class="Notice"
    >
      <div class="content">
        <div class="header">
          <div class="header-text">{{$t('login.notice')}}</div>
        </div>
        <p class="item">{{$t('login.notice_1')}}</p>
        <p class="item">{{$t('login.notice_2')}}</p>
        <div class="edit-btn">
          <div class="edit-btn-item">
            <CommonBtn :btnText="$t('common.OK')" :loading="btnLoading" @btnClick="confirm"></CommonBtn>
          </div>
          <div class="edit-btn-item">
            <CommonBtn :btnText="$t('common.toWrite')" :btnType="2" :loading="btnLoading" @btnClick="closeNotice"></CommonBtn>
          </div>
        </div>
      </div>
    </van-popup>
  </van-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { LoginV2 } from "@/api/user"
// import md5 from '@/utils/md5'
export default {
  name: 'FitstLogin',
  components: {
  },
  data() {
    return {
      visible: false,
      nickname: '',
      icode: '',
      btnLoading: false,
      notice: false,
      sign: '',
    }
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      account: state => state.wallet.account,
      usdtBalance: state => state.wallet.usdtBalance,
      ethBalance: state => state.wallet.ethBalance,
    }),
  },
  mounted() {
    this.signAction()
  },
  methods: {
    ...mapActions(['SetBalance', 'SetToken', 'GetUserInfo', 'signVerify']),
    show() {
      this.SetBalance()
      this.icode = localStorage.getItem('XEIcode') || ''
      this.visible = true
    },
    close() {
      this.visible = false
    },
    closeNotice() {
      this.notice = false
    },
    saveBefore() {
      if (!(this.nickname.length >= 2 && this.nickname.length <= 12)) {
        this.$toast(this.$t('toast.name'))
        return
      }
      if (this.icode == '') {
        this.notice = true
      } else {
        this.save()
      }
      
    },
    confirm() {
      this.notice = false
      this.save()
    },
    signAction(auto) {
      this.signVerify().then(res => {
        this.sign = res
        if (this.sign && auto) {
          this.save()
        }
      })
    },
    save() {
      // let regex=/^[0-9a-zA-Z]+$/;
      // !regex.test(this.nickname) || 
      
      // if (!this.icode) {
      //   this.$toast(this.$t('toast.icode'))
      //   return
      // }
      if (!this.sign) {
        this.signAction(true)
        return
      }
      let param = {
        username: this.account,
        password: this.sign,
        nickname: this.nickname,
        icode: this.icode
      }
      this.btnLoading = true
      LoginV2(param).then(res => {
        this.btnLoading = false
        this.$toast(`${this.$t('login.welcome')}${this.systemInfo.webname}`)
        this.visible = false
        this.SetToken(res.token)
        this.GetUserInfo()
      }).catch(err => {
        this.$toast(err)
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
:root {
  --van-popup-round-border-radius: 40px;
}
.FitstLogin {
  border-radius: 40px;
  .content {
    width: 320px;
    height: 350px;
    padding: 15px 18px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
    // border-radius: 40px;
  }
  .header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
  .detail {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .address {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: left;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #B8B6B2;
      word-break: break-all;
    }
    .symbol {
      width: 29px;
      height: 6px;
      margin-right: auto;
      margin-bottom: 10px;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    .name {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #362B45;
      line-height: 16px;
      .name-icon {
        width: 11px;
        height: 11px;
        margin-left: 10px;
      }
    }
    .balance {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      .balance-logo {
        width: 18px;
        height: 18px;
        margin-right: 14px;
      }
      .balance-key {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
      }
      .balance-value {
        flex: 1;
        text-align: right;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #EDC67F;
      }
    }
  }
  .item {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .item-title {
      width: 60px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #FFFFFF;
    }
  }
  .input {
    flex: 1;
    margin: 6px 0;
    input {
      width: 100%;
      height: 40px;
      padding: 0 16px;
      background: #292D32;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
      border-radius: 20px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #B8B6B2;
      border: 1px solid rgba(41, 45, 50, 1);
    }
  }
  .edit-btn {
    width: 276px;
    height: 40px;
    margin-top: 20px;
  }
}
.Notice {
  border-radius: 40px;
  .content {
    width: 320px;
    height: 300px;
    padding: 15px 18px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
    // border-radius: 40px;
  }

  .header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
  .item {
    margin-bottom: 2px;
    text-indent:2em;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
  }
  .edit-btn {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .edit-btn-item {
      width: 130px;
      height: 40px;
    }
  }
}
</style>
