<template>
  <div id="App">
    <HeaderNav @openWallet="openWallet"></HeaderNav>
    <WalletContent ref="WalletContent"></WalletContent>
    <FirstLogin ref="FirstLogin"></FirstLogin>
    <TrxNotice ref="TrxNotice"></TrxNotice>
    <NoticeContent ref='NoticeContent'></NoticeContent>
    <Forbidden ref="Forbidden"></Forbidden>
    <Announce ref="Announce"></Announce>
    <router-view class="child-view"></router-view>
    <div class="wait" v-if="maskLoading">
      <van-loading size="24px" vertical :loading='loading'>{{loadingText}}</van-loading>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { GetInfo } from '@/api/system.js'
import HeaderNav from '@/components/HeaderNav'
import WalletContent from '@/components/WalletContent'
import FirstLogin from '@/components/FirstLogin'
import NoticeContent from '@/components/NoticeContent'
import TrxNotice from '@/components/TrxNotice'
import Forbidden from '@/components/Forbidden'
import Announce from '@/components/Announce'
import store from '@/store'
import { announceInfo } from './api/user'
export default {
  name: 'app',
  data () {
    return {
      transitionName: 'slide-left', // 默认动态路由变化为slide-right,
      timeInterval: '',
      timeInterval2: '',
    }
  },
  components: {
    HeaderNav,
    WalletContent,
    FirstLogin,
    NoticeContent,
    TrxNotice,
    Forbidden,
    Announce
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      maskLoading: state => state.system.maskLoading,
      loadingText: state => state.system.loadingText,
      firstLogin: state => state.user.firstLogin,
      trxNotice: state => state.system.trxNotice,
      isAnnounce: state => state.system.isAnnounce,
      isForbidden: state => state.user.isForbidden,
    }),
  },
  watch: {
    firstLogin() {
      if (this.firstLogin) {
        this.$refs.FirstLogin.show()
      }
    },
    trxNotice() {
      if (this.trxNotice) {
        this.$refs.TrxNotice.show()
      }
    },
    $needLogin() {
      console.log(this.$needLogin)
    },
    isForbidden() {
      if (this.isForbidden) {
        this.$refs.Forbidden.show()
      }
    },
    isAnnounce() {
      if (this.isAnnounce) {
        this.$refs.Announce.show()
      }
    }
  },
  async mounted () {
    let icode = this.getQueryString('icode')
    localStorage.setItem('XEIcode', icode || '')
    // 设置默认语言
    if (!localStorage.getItem('XELanguage')) {
      localStorage.setItem('XELanguage', 'zh_hk')
    }
    this.getSystem()
    setTimeout(() => {
        this.InitWallet()
    }, 1000);

    
  },
  methods: {
    ...mapActions(['SetSystemInfo', 'InitWallet', 'SetIsLine']),
    getSystem() {
      GetInfo().then(res => {
        this.SetIsLine(res.isline)
        this.SetSystemInfo(res)
        window.document.title = this.systemInfo.webname
      })

      announceInfo().then(res => {
        // 首页通知
        if(res.length==0)return;
        store.dispatch('SetIsAnnounce', true)
        store.dispatch('SetAnnounceText', res)
      })
    },
    openWallet(address) {
      this.$refs.WalletContent.show(address)
    },
    getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      let r = window.location.hash.split("?")[1]?.match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    
  },
}
</script>

<style lang="scss">
#App {
  height: 100vh;
  background: rgba(0,0,0,0.8);
}

.child-view {
  // position: absolute;
  // top: 0px;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // width: 100%;
  // padding-top: 50px;
  // height: auto;
  // background: #f8f8f8;
  // background: #fff;
  // box-sizing: border-box;
  // overflow: auto;
  // -webkit-overflow-scrolling: scroll;
  /* transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease; */
  // transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}

.wait {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.8);
}
</style>
