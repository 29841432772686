import wallet from './modules/wallet';
import system from './modules/system'
import user from './modules/user'
import { createStore } from 'vuex'
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    wallet,
    system,
    user,
  }
})
